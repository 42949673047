"use client"
import { SWRConfig } from "swr"

export const SWRProvider: React.FC<{
  children: React.ReactNode
  fallback?: Record<string, any>
}> = ({ children, fallback }) => {
  return (
    <SWRConfig value={{ ...(fallback && { fallback }) }}>{children}</SWRConfig>
  )
}
